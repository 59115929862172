import React from 'react';
import Select from 'react-select';
import { MdClear } from 'react-icons/md';
import '../../styles/components/inputs/selection.scss';

const Selection = (props) => {

    const colorStyles = {
        control: (styles, { isMulti }) => ({
            ...styles,
            width: "100%",
            color: "white",
            backgroundColor: "#636363",
            border: "none",
            height: isMulti ? '100%' : 35,
            marginTop: 5,
            boxShadow: "1px 1px 3px #000"
        }),
        menuList: (styles) => ({
            ...styles,
            backgroundColor: '#636363',
            "::-webkit-scrollbar": {
                width: "0px"
            },
        }),
        dropdownIndicator: (styles) => ({ 
            ...styles,
            color: 'white',
            '&:hover': {
                color: '#00bfff'
            }
        }),
        input: (styles) => ({ ...styles, color: 'white'}),
        option: (styles, { isFocused, isSelected}) => ({
            ...styles,
            border: "none",
            backgroundColor: isSelected ? '#858282' : isFocused ? '#858282' : "#636363",
            color: isSelected ? 'blacl' : 'white',
            ':active': {
                ...styles[':active'],
                backgroundColor: '#858282'
            }
        }),
        placeholder: (styles) => ({ ...styles, color: 'white'}),
        singleValue: (styles, {}) => ({ ...styles, color: 'white'}),
        multiValue: (styles) => {
            return {
                ...styles,
                backgroundColor: '#00bfff',
                fontWeight: "bold",
                color: 'black'
            }
        },
        multiValueRemove: (styles) => ({
            ...styles,
            color: 'black',
            ':hover': {
              backgroundColor: '#00bfff',
              color: 'white',
            },
        })
    };

    return (
        <div className='legendary-selection-input'>
            <div className='legendary-selection-input-title-container'>
                <span className='legendary-selection-input-title'>{props.title}</span>
                {(props.clickableText && props.value) && <span onClick={props.onClickOfText} className='legendary-text-input-clickable'>{`(${props.clickableText})`}</span>}
            </div>
            <div className={`legendary-selection-input-container`} style={{
                zIndex: props.zIndex
            }}>
                <Select
                    filterOption={props.filterOption}
                    isDisabled={props.disabled}
                    options={props.options}
                    onChange={props.onChange}
                    value={props.selectedOption}
                    styles={colorStyles}
                    placeholder={props.placeholder}
                    components={props.components}
                    isMulti={props.isMulti}
                />
            </div>
            {props.error && <div className='legendary-selection-input-error'>
                {props.error}
            </div>}
        </div>
    );
};

export default Selection;