import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import itemsService from '../../services/items.js';
import '../../styles/components/pages/items.scss';

const Items = () => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        const loadItems = async () => {
            setLoading(true);

            try {
                const response = await itemsService.getItems();

                setItems(response.data);
            } catch (error) {
                console.error('Error loading items:', error);
            }

            setLoading(false);
        }

        loadItems();
    }, []);

    if (loading) {
        return (
            <div className='items-loading'>
                <div className='items-loading-container'>
                    <ReactLoading type="bars" height={100} width={100}/>
                    <div className='loading-text'>{"Loading Items"}</div>
                </div>
            </div>
        )
    }

    return (
        <div className='items'>
            <div className='items-table'>
                <div className='items-table-header'>
                    <div className='items-table-header-id'>ID</div>
                    <div className='items-table-header-item'>Item</div>
                    <div className='items-table-header-label'>Label</div>
                    <div className='items-table-header-limit'>Limit</div>
                </div>
                <div className='items-table-content'>
                    {items.map((item, index) => {
                        return (
                            <div key={index} className='items-table-row'>
                                <div className='items-table-row-id'>{item.id}</div>
                                <div className='items-table-row-item'>{item.item}</div>
                                <div className='items-table-row-label'>{item.label}</div>
                                <div className='items-table-row-limit'>{item.limit}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Items;