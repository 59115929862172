import React, { useState, useEffect } from 'react';
import itemsService from '../../services/items.js';
import '../../styles/components/pages/developer.scss';
import { InputLabel, MenuItem, Select, TextField } from '@mui/material';
import LegendaryTextInput from '../inputs/TextInput.js';
import TextInput from '../inputs/TextInput.js';
import ToggleInput from '../inputs/ToggleInput.js';
import Selection from '../inputs/Selection.js';
import items from '../../services/items.js';
import itemGroups from '../../services/itemGroups.js';

const professionSelections = [
    { value: 'Blacksmith', label: 'Blacksmith' },
    { value: 'Carpenter', label: 'Carpenter' },
    { value: 'Smelter', label: 'Smelter' },
    { value: 'Refining', label: 'Refining' },
    { value: 'Gunsmith', label: 'Gunsmith' },
    { value: 'Saloon', label: 'Saloon' },
    { value: 'Drugs', label: 'Drugs' },
    { value: 'HorseTrainer', label: 'Horse Trainer' },
    { value: 'Doctor', label: 'Doctor' },
    { value: 'Train', label: 'Train' },
    { value: 'Tobacco', label: 'Tobacco' },
    { value: 'Distiller', label: 'Distiller' },
    { value: 'Rancher', label: 'Rancher' },
    { value: 'Florist', label: 'Florist' },
    { value: 'GeneralStore', label: 'General Store' },
    { value: 'PetTrainer', label: 'Pet Trainer' },
    { value: 'Herbalist', label: 'Herbalist' },
];

const experienceSelections = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
];

const categorySelections = [
    { value: 'ammo', label: 'Ammo'},
    { value: 'food', label: 'Food'},
    { value: 'ingredient', label: 'Ingredient'},
    { value: 'drink', label: 'Drink'},
    { value: 'meleeWeapon', label: 'Melee Weapons'},
    { value: 'weaponParts', label: 'Weapon Parts'},
    { value: 'materials', label: 'Materials'},
    { value: 'toolParts', label: 'Tool Parts'},
    { value: 'wagonParts', label: 'Wagon Parts'},
    { value: 'bundles', label: 'Bundles'},
    { value: 'chairs', label: 'Chairs'},
    { value: 'tables', label: 'Tables'},
    { value: 'fires', label: 'Fires'},
    { value: 'decorations', label: 'Decorations'},
    { value: 'benches', label: 'Benches'},
    { value: 'dressers', label: 'Dressers'},
    { value: 'chests', label: 'Chests'},
    { value: 'lights', label: 'Lights'},
    { value: 'beds', label: 'Beds'},
    { value: 'tents', label: 'Tents'},
    { value: 'tools', label: 'Tools'},
    { value: 'personalCare', label: 'Personal Care'},
    { value: 'repairs', label: 'Repairs'},
    { value: 'spices', label: 'Spices'},
    { value: 'paperProducts', label: 'Paper Products'},
    { value: 'refining', label: 'Refining'},
    { value: 'foodContainers', label: 'Food Containers'},
    { value: 'fishingBait', label: 'Fishing Bait'},
    { value: 'furnitureParts', label: 'Furniture Parts'},
    { value: 'horse', label: 'Horse'},
    { value: 'smelting', label: 'Smelting'},
    { value: 'revolvers', label: 'Revolvers'},
    { value: 'pistols', label: 'Pistols'},
    { value: 'shotgun', label: 'Shotguns'},
    { value: 'repeaters', label: 'Repeaters'},
    { value: 'rifles', label: 'Rifles'},
    { value: 'horseCare', label: 'Horse Care'},
    { value: 'nutrition', label: 'Nutrition'},
    { value: 'reproduction', label: 'Reproduction'},
    { value: 'certificates', label: 'Certificates'},
    { value: 'crates', label: 'Crates'},
    { value: 'valuables', label: 'Valuables'},
    { value: 'cigarette', label: 'Cigarettes'},
    { value: 'cigars', label: 'Cigars'},
    { value: 'chew', label: 'Chew'},
    { value: 'supplies', label: 'Supplies'},
    { value: 'tinctures', label: 'Tinctures'},
    { value: 'medications', label: 'Medications'},
    { value: 'tobacco', label: 'Tobacco'},
    { value: 'peltCutting', label: 'Pelt Cutting'},
    { value: 'farming', label: 'Farming'},
    { value: 'goods', label: 'Goods'},
    { value: 'trainTicket', label: 'Train Tickets'},
    { value: 'scratchOff', label: 'Scratch Offs'},
    { value: 'treats', label: 'Treats'},
    { value: 'petHealth', label: 'Pet Health'},
    { value: 'petNutrition', label: 'Pet Nutrition'},
    { value: 'alcohol', label: 'Alcohol'},
    { value: 'patreon', label: 'Patreon'},
    { value: 'drugs', label: 'Drugs'},
    { value: 'still', label: 'Still'},
    { value: 'moonshine', label: 'Moonshine'},
    { value: 'explosives', label: 'Explosives'},
    { value: 'cropBag', label: 'Crop Bags'},
    { value: 'seed', label: 'Seed'},
];

function toCamelCase(str) {
    return str.split(' ').map((word, index) => {
        if (index === 0) {
            return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join('');
}

const Developer = () => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [itemSelections, setItemSelections] = useState([]);
    const [itemGroupSelections, setItemGroupSelections] = useState([]);
    const [itemName, setItemName] = useState('');
    const [itemId, setItemId] = useState('');
    const [itemGroup, setItemGroup] = useState('');
    const [description, setDescription] = useState('');
    const [quantity, setQuantity] = useState('0');
    const [time, setTime] = useState('5');
    const [isWeapon, setIsWeapon] = useState(false);
    const [category, setCategory] = useState(null);
    const [profession, setProfession] = useState(null);
    const [professionLevel, setProfessionLevel] = useState(null);
    const [code, setCode] = useState('');
    const [sql, setSql] = useState('');

    const generateSqlCode = () => {
        const code = `INSERT INTO items VALUES ('${itemId}', '${itemName}', 20, 1, 'item_standard', 1, NULL, ${itemGroup?.value || '1'}, '{}', '${description}');`;

        setSql(code);
    }

    const generateLuaCode = () => {
        let code = '';

        if (profession) {
            code = `${toCamelCase(itemName) || 'UNKNOWN'} = {
    category = categories.${category?.value || 'Unknown'}Category,
    categoryImage = categories.${category?.value || 'Unknown'}CategoryImage,
    profession = {"${profession?.value || ''}", ${professionLevel?.value || 0}},
    professionExp = 10,
    hideRecipe = false,
    item = "${itemId}",
    label = "${itemName}",
    count = ${quantity},
    weapon = ${isWeapon},
    grade = nil,
    description = "${description}",
    items = {
        ${items.map((itemInList, index) => {
            const item = itemSelections.find(i => i.value === itemInList.item) || {label: 'Unknown', item: 'Unknown', quantity: 0};
            return `${index > 0 ? "\n\t" : ''}{label = "${item.item.label}", item = "${item.item.item}", count = ${itemInList.quantity}}`;
        })}
    },
    propHash = {},
    giveBack = {},
    time = ${time},
},
        `;
        } else {
            code = `${toCamelCase(itemName) || 'UNKNOWN'} = {
    category = categories.${category?.value || 'Unknown'}Category,
    categoryImage = categories.${category?.value || 'Unknown'}CategoryImage,
    hideRecipe = false,
    item = "${itemId}",
    label = "${itemName}",
    count = ${quantity},
    weapon = ${isWeapon},
    grade = nil,
    description = "${description}",
    items = {
        ${items.map((itemInList, index) => {
            const item = itemSelections.find(i => i.value === itemInList.item) || {label: 'Unknown', item: 'Unknown', quantity: 0};
            return `${index > 0 ? "\n\t" : ''}{label = "${item.item.label}", item = "${item.item.item}", count = ${itemInList.quantity}}`;
        })}
    },
    propHash = {},
    giveBack = {},
    time = ${time},
},
`;
        }

        setCode(code);
    };

    const generateConstantCase = (input) => {
        const words = input.split(' ');
        const constantWords = words.map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
        return constantWords.join('_');
    }

    useEffect(() => {
        const loadItems = async () => {
            setLoading(true);

            try {
                const response = await itemsService.getItems();
                const groups = await itemGroups.getItemGroups();

                const newSelections = response.data.map((item) => {
                    return {
                        value: item.item,
                        label: item.label,
                        item: item
                    };
                });

                const newGroups = groups.data.map((group) => {
                    return {
                        value: group.id,
                        label: group.description.toUpperCase()
                    }
                });

                setItemSelections(newSelections);
                setItemGroupSelections(newGroups);
            } catch (error) {
                console.error('Error loading items:', error);
            }

            setLoading(false);
        };

        loadItems();
    }, []);

    useEffect(() => {
        generateLuaCode();
        generateSqlCode();
    }, [itemName, itemId, category, profession, professionLevel, description, quantity, time, isWeapon, items, itemGroup]);

    return (
        <div className='developer'>
            <div className='recipe-generator'>
                <div className='basic-info'>
                    <div className='basic-info-header'>
                        <h2>Basic Information</h2>
                    </div>
                    <div className='basic-info-container'>
                        <TextInput title='Item Name' value={itemName} onChange={(name) => {
                            setItemName(name);
                            setItemId(generateConstantCase(name));
                        }}/>
                        <TextInput title='Item ID' value={itemId} onChange={(id) => {
                            setItemId(id);
                        }}/>
                        <TextInput title='Description' value={description} onChange={(description) => {
                            setDescription(description);
                        }}/>
                        <Selection
                            title={'Group'}
                            placeholder={'Select Item Group'}
                            options={itemGroupSelections}
                            onChange={(typeSelection) => {
                                setItemGroup(typeSelection);
                            }}
                            selectedOption={itemGroup ? itemGroupSelections.find(t => t.value === itemGroup) : null}
                        />
                        <Selection
                            title={'Category'}
                            placeholder={'Select Category'}
                            options={categorySelections}
                            onChange={(typeSelection) => {
                                setCategory(typeSelection);
                            }}
                            selectedOption={category ? categorySelections.find(t => t.value === category) : null}
                        />
                        <Selection
                            title={'Profession'}
                            placeholder={'Select Profession'}
                            options={professionSelections}
                            onChange={(typeSelection) => {
                                setProfession(typeSelection);
                            }}
                            selectedOption={profession ? professionSelections.find(t => t.value === profession) : null}
                        />
                        <Selection
                            title={'Profession Level'}
                            placeholder={'Select Profession Level'}
                            options={experienceSelections}
                            onChange={(typeSelection) => {
                                setProfessionLevel(typeSelection);
                            }}
                            selectedOption={professionLevel ? experienceSelections.find(t => t.value === professionLevel) : null}
                        />
                        <TextInput title='Quantity Created' value={quantity} onChange={(quantity) => {
                            setQuantity(quantity);
                        }}/>
                        <ToggleInput value={isWeapon} onChange={(e) => {
                            setIsWeapon(e.target.checked);
                        }} label={'Is this item a weapon?'}/>
                        <TextInput title='Crafting Time (Seconds)' value={time} onChange={(time) => {
                            setTime(time);
                        }}/>
                    </div>
                </div>
                <div className='item-recipes'>
                    <div className='item-recipes-header'>
                        <h2>Recipe</h2>
                    </div>
                    <div className='item-recipes-container'>
                        {items.map((item, index) => {
                            return (
                                <RecipeItem
                                    key={index}
                                    index={index}
                                    items={items}
                                    itemSelections={itemSelections}
                                    quantity={item.quantity}
                                    selectedItem={item.item}
                                    setItems={setItems}
                                    setQuantity={setQuantity}
                                />
                            )
                        })}
                    </div>
                    <div 
                        className='item-recipes-add-item'
                        onClick={() => {
                            setItems([...items, {item: null, quantity: 0}]);
                        }}
                    > Add Item </div>
                </div>
                <div className='item-code'>
                    <div className='item-code-container'>
                        <div className='item-code-header'>
                            <h2>Item Code</h2>
                        </div>
                        <textarea readOnly className='item-code-display' value={code} />
                    </div>
                    <div className='item-code-container'>
                        <div className='item-code-header'>
                            <h2>SQL</h2>
                        </div>
                        <textarea readOnly className='item-code-display' value={sql} />
                    </div>
                </div>
            </div>
        </div>
    );
}

const RecipeItem = ({itemSelections, quantity, items, setItems, index}) => {
    return (
        <div className='item-recipe-item'>
            <div className='item-recipe-item-selection'>
                <Selection
                    title={'Item'}
                    placeholder={'Select Item'}
                    options={itemSelections}
                    onChange={(itemSelection) => {
                        const newItems = [...items];
                    
                        newItems[index].item = itemSelection.value;
                        setItems(newItems);
                    }}
                    selectedOption={items[index] ? itemSelections.find(t => t.value === items[index].item) : null}
                    />
            </div>
            <div className='item-recipe-item-quantity'>
                <TextInput title='Count' value={quantity} onChange={(value) => {
                    const newItems = [...items];

                    newItems[index].quantity = value;
                    setItems(newItems);
                }}/>
            </div>
            <div className='item-recipe-item-remove' onClick={() => {
                const newItems = [...items];

                newItems.splice(index, 1);
                setItems(newItems);
            }}>
                X
            </div>                     
        </div>
    )
}

export default Developer;