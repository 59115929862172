import React from 'react';
import '../../styles/components/inputs/text-input.scss';

const TextInput = (props) => {
    return (
        <div className='legendary-text-input'>
            <div className='legendary-text-input-title-container'>
                <span className='legendary-text-input-title'>{props.title}</span>
                {(props.clickableText && props.value) && <span onClick={props.onClickOfText} className='legendary-text-input-clickable'>{`(${props.clickableText})`}</span>}
            </div>
            <div className='legendary-text-input-container'>
                <input 
                    readOnly={props.readOnly} 
                    type={props.secure ? 'password' : 'text'}
                    className={`legendary-text-input-value${props.readOnly ? ' read-only' : ''}${props.onClick !== undefined ? ' clickable' : ''}`}
                    onClick={() => {
                        if (props.onClick) {
                            props.onClick();
                        }
                    }}
                    onChange={(e) => {
                        props.onChange(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && props.onEnterPress) {
                            props.onEnterPress();
                        }
                    }}
                    value={props.value}
                />
            </div>
            {props.error && <div className='legendary-text-input-error'>
                {props.error}
            </div>}
        </div>
    );
};

export default TextInput;