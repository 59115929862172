import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import { Toaster } from 'react-hot-toast';
import Home from './components/pages/Home';
import Developer from './components/pages/Developer';
import Items from './components/pages/Items';
import Header from './components/header/Header';
import Whitelist from './components/pages/Whitelist';

const LegendaryHome = () => {
    return (
        <BrowserRouter>
            <App>
                <Toaster/>
                {/* <Header/> */}
                <div className='content-container'>
                    <Routes>
                        <Route
                            path='/'
                            element={<Home/>}
                            exact
                        />
                        <Route
                            path='/developer'
                            element={<Developer/>}
                            exact
                        />
                        <Route
                            path='/items'
                            element={<Items/>}
                            exact
                        />
                        <Route
                            path='/whitelist'
                            element={<Whitelist/>}
                            exact
                        />
                    </Routes>
                </div>
            </App>
        </BrowserRouter>
    )
};

export default LegendaryHome;