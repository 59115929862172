import React, { useState, useEffect } from 'react';

const Whitelist = () => {
    return (
        <div className='whitelist'>
            Whitelist
        </div>
    );
}

export default Whitelist;