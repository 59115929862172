import React from 'react';
import '../../styles/components/inputs/toggle.scss';
import Toggle from 'react-toggle';

const ToggleInput = (props) => {
    return (
        <div className='toggle'>
            <Toggle
                defaultChecked={props.value}
                onChange={props.onChange} 
            />
            <div className='toggle-label'>{props.label}</div>
        </div>
    )
};

export default ToggleInput;